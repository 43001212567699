import { template as template_8cffb2df83e14d10a264ee67347ab2ec } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8cffb2df83e14d10a264ee67347ab2ec(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
