import { template as template_c769a6e11b2f4d40a5e2eae833034878 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c769a6e11b2f4d40a5e2eae833034878(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
