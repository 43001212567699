import { template as template_4306b07f78ba4a5f9930429a5e724048 } from "@ember/template-compiler";
const WelcomeHeader = template_4306b07f78ba4a5f9930429a5e724048(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
