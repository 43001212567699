import { template as template_788ab34c3442422bb97cd2580763014f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_788ab34c3442422bb97cd2580763014f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
