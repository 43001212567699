import { template as template_8a4d70a7456d438f9be9d58be47a3353 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8a4d70a7456d438f9be9d58be47a3353(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
