import { template as template_e2162f4549f1468fbc4e3e9dee472c53 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e2162f4549f1468fbc4e3e9dee472c53(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
