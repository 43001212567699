import { template as template_f1b8bacef38c43e5938401ffc5138e47 } from "@ember/template-compiler";
const FKLabel = template_f1b8bacef38c43e5938401ffc5138e47(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
